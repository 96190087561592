import React from 'react';
import SearchFacetDates from '../searchFacetDates/index';
const SearchFacetFilter = props => {
  let className = 'search-facet-filters__filter';
  let items = props.items
    ? props.items
        .filter(item => {
          return item.count > 0;
        })
        .sort((a, b) => a.weight - b.weight)
    : [];
  let counts = '';

  const filters = (
    <ul className="search-facet-filters__list">
      {items.length > 0 &&
        items.map(item => {
          let itemClassName = className;
          if (props.counts) {
            counts = '(' + item.count + ')';
          }
          if (props.appliedFacets.some(i => i.id === item.id && i.type === item.type)) {
            itemClassName += ' search-facet-filters__filter--active';
          }
          if (item.selected) {
            itemClassName += ' search-facet-filters__filter--selected';
          }
          return (
            <li
              className={itemClassName}
              key={item.id}
              onClick={e => {
                props.handleAddFacet(item, e) && props.toggleFacetList(e);
              }}
            >
              {item.name} {counts}
            </li>
          );
        })}
      {items.length === 0 && (
        <li class="search-facet-filters__empty">No further filtering is available for this category.</li>
      )}
    </ul>
  );

  return (
    <div className="search-facet-filter">
      {props.title && <p>{props.title.charAt(0).toUpperCase() + props.title.slice(1)}</p>}
      <div className="search-facet-filters__row">
        {!props.loading && filters}
        {props.loading && <img src="/sites/all/libraries/rct_collection_online/app/images/tail-spin.svg" />}
      </div>
    </div>
  );
};

export default SearchFacetFilter;
