import './video-promo.tpl.php';
import './style.scss';

// Dirty hack to make this available to angular.
jQuery(showVideoPromo);
window.videoPromo = showVideoPromo;

function showVideoPromo() {
  jQuery('.video-promo').each((i, elem) => {
    const el = jQuery(elem);
    el.parent().addClass('supports-video-promo');

    const src = el
      .find('.video-promo__modal-window')
      .children('iframe')
      .attr('src');
    el.find('.video-promo__play-button').click(e => {
      e.preventDefault();
      el.find('.video-promo__modal-window')
        .children('iframe')
        .attr('src', src);
      el.find('.video-promo__modal').show();
      jQuery('body').addClass('modal-open');
    });

    el.find('.video-promo__close-button').click(e => {
      e.preventDefault();
      el.find('.video-promo__modal-window')
        .children('iframe')
        .attr('src', '');
      el.find('.video-promo__modal').hide();
      jQuery('body').removeClass('modal-open');
    });
  });
}
