import './paragraph-image-text-overlay.tpl.php';
import './style.scss';

jQuery($ => {
  const el = $('.paragraph-image-text-overlay');

  if (el.length < 1) {
    return;
  }

  const tab = $('.paragraph-image-text-overlay__caption-wrapper--tab');
  tab.each(function() {
    $(this).on('click', function() {
      $(this)
        .closest('.paragraph-image-text-overlay__caption-wrapper')
        .toggleClass('open');
    });
  });

  setTimeout(function() {
    const media = $('.paragraph-image-text-overlay__media');
    media.each(function() {
      const img = this.querySelector('img');
      this.style.backgroundImage = `url(${img.src})`;
      img.style.display = 'none';
    });
  }, 100);

  // Parallax
  function simpleParallax(element, image, percentage) {
    const scroll = window.scrollY || window.pageYOffset;
    const elTop = element.getBoundingClientRect().top + scroll;
    const elBottom = element.getBoundingClientRect().bottom + scroll;
    const elHeight = elBottom - elTop;
    const viewportBottom = scroll + window.innerHeight;
    const elPositionTop = viewportBottom - elTop;
    const posBottom = elPositionTop / (elHeight + window.innerHeight);

    if (elPositionTop >= 0 && posBottom < 1) {
      const imagePos = posBottom * percentage;
      image.style.transform = `translateY(-${imagePos}%)`;
    }
  }

  // Request animation frame
  const raf =
    window.requestAnimationFrame ||
    function(callback) {
      window.setTimeout(callback, 1000 / 60);
    };

  const handler = () =>
    raf(() => {
      const mediaWrap = [...document.querySelectorAll('.paragraph-image-text-overlay__wrapper')];
      mediaWrap.forEach((element, key) => {
        const image = element.querySelector('.paragraph-image-text-overlay__media');
        const percentage = 45;
        const height = 130 + percentage;
        image.style.height = `${height}%`;
        simpleParallax(element, image, percentage);
      });
    });

  handler();
  window.addEventListener('scroll', handler);
});
