import React, { Component } from 'react';
import debounce from 'lodash.debounce';
import jsonUrl from './data.json';
import SearchInput from './SearchInput';
import AutoComplete from './AutoComplete';
import he from 'he';

import './style.scss';
import { strict } from 'assert';

class SearchAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.defaultSearchText,
      error: null,
      isLoaded: false,
      isVisible: false,
      items: [],
      cursor: -1,
      minAutocomplete: 3,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyword = this.handleKeyword.bind(this);
    this.handleAddFacet = this.handleAddFacet.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);

    this.request = debounce(this.request, 500);
  }

  handleChange(value) {
    this.setState({ value });
    this.request(value);
  }

  handleBlur() {
    setTimeout(() => {
      this.setState({ isVisible: false });
    }, 500);
  }

  handleFocus(e) {
    this.setState({ isVisible: true });
  }

  request(value) {
    if (this.props.jump === 'jump') {
      return;
    }
    this.setState({
      isLoaded: false,
      items: [],
      isVisible: true,
      cursor: -1,
    });
    if (value.length >= this.state.minAutocomplete) {
      const requestData = this.props.angularScope
        ? this.props.angularScope.reactAutocomplete(value)
        : this._getMockData();
      requestData.then(result => {
        if (result.results) {
          result.results.forEach(r => {
            r.name = he.decode(r.name);
          });
        } else {
          result.results = [];
        }
        let facetResults = this.props.facets
          .reduce(function(accumulator, currentValue) {
            return Array.isArray(currentValue.data) ? accumulator.concat(currentValue.data) : accumulator;
          }, [])
          .filter(e => {
            return e.count && e.name.match(new RegExp(value, 'i'));
          });
        result.results = result.results
          .concat(facetResults)
          .sort((a, b) => {
            return b.count - a.count;
          })
          .slice(0, 4);
        this.setState({
          isLoaded: true,
          items: result.results,
        });
      });
    } else {
      this.setState({ isLoaded: false });
    }
  }

  _getMockData() {
    return fetch(jsonUrl + `?value=$(encodeURIComponent(value))`);
  }

  handleKeyDown(e) {
    // Up Arrow
    if (e.keyCode === 38) {
      this.setState(prevState => ({
        cursor: Math.max(prevState.cursor - 1, -1),
      }));
      e.preventDefault();
      // Down Arrow
    } else if (e.keyCode === 40) {
      this.setState(prevState => ({
        cursor: Math.min(prevState.cursor + 1, prevState.items.length - 1),
      }));
      e.preventDefault();
      // Return key
    } else if (e.keyCode === 13) {
      if (this.state.cursor < 0) {
        this.props.handleKeyword(this.state.value);
      } else {
        this.props.handleAddFacet(this.state.items[this.state.cursor]);
      }
      this.setState({
        value: '',
        isVisible: false,
      });
    }
  }

  handleAddFacet(item) {
    this.setState({
      value: '',
    });
    this.props.handleAddFacet(item);
  }

  handleKeyword(value) {
    this.setState({
      value: '',
    });
    this.props.handleKeyword(value);
  }

  render() {
    if (this.props.hasSearchBox) {
      return (
        <div className="search-auto-complete">
          {
            <SearchInput
              value={this.state.value}
              handleChange={this.handleChange}
              handleKeyDown={this.handleKeyDown}
              handleKeyword={this.handleKeyword}
              handleFocus={this.handleFocus}
              handleBlur={this.handleBlur}
              placeholder={this.props.placeholder}
            />
          }

          {this.state.value.length > 0 && this.props.jump !== 'jump' && (
            <AutoComplete
              value={this.state.value}
              isLoaded={this.state.isLoaded}
              isVisible={this.state.isVisible}
              items={this.state.items}
              error={this.state.null}
              cursor={this.state.cursor}
              handleAddFacet={this.handleAddFacet}
              handleKeyword={this.handleKeyword}
              minAutocomplete={this.state.minAutocomplete}
            />
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default SearchAutoComplete;
