import './group-navigation.tpl.php';
import './style.scss';

jQuery($ => {
  if ($('.group-navigation').length < 1) {
    return false;
  }

  let navTimer = null;

  window.addEventListener(
    'scroll',
    function(event) {
      var top = this.scrollY;
      if (top >= 400) {
        $('body').addClass('group-navigation-in-view');
      } else {
        $('body').removeClass('group-navigation-in-view');
      }
      if (top >= $(document).height() - $('.footer').height() * 2.7) {
        $('.group-navigation:not(.window)').css('opacity', 1);
        $('body').removeClass('group-navigation-in-view');
      } else {
        $('.group-navigation:not(.window)').css('opacity', 0);
        $('body').addClass('group-navigation-in-view');
      }
      clearTimeout(navTimer);
      navTimer = setTimeout(
        function() {
          $('body').removeClass('group-navigation-in-view');
        }.bind(this),
        1000
      );
    },
    false
  );

  $('.group-navigation .next').click(function(e) {
    var $carousel = jQuery(
      '.group-theme-meta-object-carousel__init-slick-carousel.group-theme-meta-object-carousel__carousel'
    ).get(0).slick;
    var total = $carousel.slideCount;
    var current = $carousel.getCurrent();
    if (current < total - 1) {
      $('.group-theme-meta-object-carousel__init-slick-carousel.group-theme-meta-object-carousel__carousel')
        .get(0)
        .slick.next();
      return false;
    }
    if ($(this).attr('href') == '/:placeholder:') {
      return false;
    }
  });

  $('.group-navigation .prev').click(function(e) {
    var $carousel = jQuery(
      '.group-theme-meta-object-carousel__init-slick-carousel.group-theme-meta-object-carousel__carousel'
    ).get(0).slick;
    var current = $carousel.getCurrent();
    if (current > 0) {
      $('.group-theme-meta-object-carousel__init-slick-carousel.group-theme-meta-object-carousel__carousel')
        .get(0)
        .slick.prev();
      return false;
    }
    if ($(this).attr('href') == '/:placeholder:') {
      return false;
    }
  });
});
