import './paragraph-job-feed.tpl.php';
import './style.scss';

jQuery(document).ready(function($) {
  $('.paragraph-job-feed__icon-block p').each(function() {
    var data = $(this)
      .html()
      .split('<br>');
    var html = '';
    $(data).each(function(value, text) {
      if (text.length < 1) {
        return;
      }
      var cssClass = '';
      switch (value) {
        case 0:
          cssClass = 'icon-RCT-job';
          break;
        case 1:
          cssClass = 'icon-RCT-location';
          break;
        case 2:
          cssClass = 'icon-RCT-date';
          break;
        default:
          cssClass = '';
          break;
      }
      html += '<span class="' + cssClass + '"></span>' + text + '<br>';
    });
    $(this).html(html);
  });
});
