import React from 'react';

const SearchInput = props => {
  return (
    <div className="search-auto-complete__input">
      <input
        type="search"
        name="query"
        placeholder={props.placeholder}
        autoComplete="off"
        value={props.value}
        onChange={event => props.handleChange(event.target.value)}
        onKeyDown={event => props.handleKeyDown(event)}
        onFocus={event => props.handleFocus(event)}
        onBlur={event => props.handleBlur(event)}
      />
      <button
        type="submit"
        className="search-auto-complete__button"
        onClick={event => props.handleKeyword(props.value)}
      />
    </div>
  );
};

export default SearchInput;
