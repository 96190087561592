import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SearchFacetFilters from './searchFacetFilters/index';
import SearchFacetsApplied from './searchFacetsApplied/index';
import SearchAutoComplete from './searchAutoComplete/index';

import es6Promise from 'es6-promise';

import './searchFacetsApplied';
import './searchFacetFilters';
import './searchAutoComplete';
import './react-search.tpl.php';
import moment from 'moment';

const REACT_SEARCH_MICROSITE_FACETS = 'react_search_microsite_facets';
const SEARCH_TYPE_MICROSITE = 'microsite';

es6Promise.polyfill();

document.addEventListener('DOMContentLoaded', function(event) {
  class ReactSearch extends Component {
    constructor(props) {
      super(props);
      const angularScope =
        typeof window.angular !== 'undefined' ? window.angular.element(el.dataset.angularSelector).scope() : null;
      this.state = {
        appliedFacets: [],
        facets: [],
        keyword: '',
        date: '',
        isActiveDate: '',
        angularScope: angularScope,
      };

      if (angularScope && typeof angularScope.reactAppliedFacets === 'function') {
        angularScope.reactAppliedFacets().then(result => {
          this.setState({
            appliedFacets: result.appliedFacets,
            keyword: result.keyword,
          });
        });
      }
      this.handleAddFacet = this.handleAddFacet.bind(this);
      this.handleFacetList = this.handleFacetList.bind(this);
      this.handleRemoveFacet = this.handleRemoveFacet.bind(this);
      this.handleKeyword = this.handleKeyword.bind(this);
      this.handleRemoveKeyword = this.handleRemoveKeyword.bind(this);
      this.handleDate = this.handleDate.bind(this);
      this.handleRemoveDate = this.handleRemoveDate.bind(this);
    }

    componentDidMount() {
      this.micrositeComponentDidMount();
    }

    micrositeComponentDidMount() {
      // Clear the microsite search facets straight away when a user visits a 'non-microsite' search page.
      if (this.state.angularScope.searchType != SEARCH_TYPE_MICROSITE) {
        this.clearFacetsStorage(REACT_SEARCH_MICROSITE_FACETS);
        return;
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.state.angularScope.searchType != SEARCH_TYPE_MICROSITE) {
        return;
      }

      if (prevState.appliedFacets || prevState.keyword) {
        if (this.state.keyword.match(/\w.*\b(--)\w.*?(?:(?!--).)*/g)) {
          this.state.keyword = this.state.keyword.replace(/--/g, '/');
        }
        const nonApplicableSearchPageVisitCounter = 0; // The counter for when the user visits a page that is not the applicable search page.
        const searchFacetsStorage = {
          [SEARCH_TYPE_MICROSITE]: {
            keyword: this.state.keyword,
            filters: this.state.appliedFacets,
            current_page: window.location.href,
            non_applicable_search_page_visit_counter: nonApplicableSearchPageVisitCounter,
          },
        };
        localStorage.setItem(REACT_SEARCH_MICROSITE_FACETS, JSON.stringify(searchFacetsStorage));
      }
    }

    clearFacetsStorage(searchFacetType) {
      clearFacetsStorage(searchFacetType);
    }

    handleAddFacet(item) {
      if (this.state.appliedFacets.some(i => i.id === item.id && i.type === item.type)) {
        return false;
      }
      if (item.query) {
        if (item.selected) {
          window.location = window.location.origin + window.location.pathname;
        } else {
          window.location = item.query;
        }
      } else if (item.type === 'jump') {
        this.scrollToElem(item);
      } else {
        if (this.state.angularScope) {
          this.state.angularScope.reactFacetSelect(item);
        }
        this.setState({
          appliedFacets: [...this.state.appliedFacets, item],
        });
        return true;
      }
    }

    handleFacetList(facets) {
      this.setState({ facets: facets });
    }

    scrollToElem(item) {
      let offset = 0;
      const target = document.getElementById(item.id);

      if (target !== null) {
        offset = target.offsetTop - 80;
      }
      window.scroll({
        behavior: 'smooth',
        top: offset,
      });
    }

    handleRemoveFacet(index) {
      this.setState(prevState => {
        const appliedFacets = [...prevState.appliedFacets];
        let removed = appliedFacets.splice(index, 1);
        if (this.state.angularScope) {
          this.state.angularScope.reactFacetDeselect(removed.shift());
        }
        return { appliedFacets };
      });
    }

    handleKeyword(keyword) {
      if (this.state.angularScope) {
        if (keyword.length) {
          this.state.angularScope.reactOrderBy('relevancy');
        } else {
          this.state.angularScope.reactOrderBy('date');
        }
        this.state.angularScope.setSearchTerm(keyword);
      } else if (this.props.jump) {
        window.location = window.location.origin + '/search/site/' + keyword;
      }
      this.setState({
        keyword: keyword,
      });
      addAnalyticsTracking(keyword);
    }

    handleRemoveKeyword(e) {
      if (this.state.angularScope) {
        this.state.angularScope.reactOrderBy('date');
        this.state.angularScope.setSearchTerm();
      }
      this.setState({
        keyword: '',
      });
    }

    handleDate(date) {
      let activeDate = date.text;

      if (date.type === 'custom') {
        activeDate = '';
      }

      if (date.type === 'custom_year') {
        activeDate = '';
      }

      if (this.state.angularScope) {
        this.state.angularScope.reactAddDate(date);
      }

      this.setState({
        date: date.text.charAt(0).toUpperCase() + date.text.slice(1),
        isActiveDate: activeDate,
      });
    }

    handleRemoveDate(date) {
      if (this.state.angularScope) {
        this.state.angularScope.reactRemoveDate();
      }

      this.setState({
        date: '',
        isActiveDate: '',
      });
    }

    render() {
      return (
        <div className="react-search__wrapper">
          <SearchAutoComplete
            items={this.state.items}
            handleAddFacet={this.handleAddFacet}
            handleKeyword={this.handleKeyword}
            angularScope={this.state.angularScope}
            jump={this.props.jump}
            hasSearchBox={this.props.has_search_box}
            defaultSearchText={this.props.default_search_text}
            facets={this.state.facets}
            placeholder={this.props.placeholder}
          />
          {(this.state.appliedFacets.length > 0 || this.state.keyword.length > 0 || this.state.date) &&
            !this.props.jump && (
              <SearchFacetsApplied
                appliedFacets={this.state.appliedFacets}
                keyword={this.state.keyword}
                date={this.state.date}
                removeFacet={this.handleRemoveFacet}
                removeKeyword={this.handleRemoveKeyword}
                removeDate={this.handleRemoveDate}
                hasSearchBox={this.props.has_search_box}
              />
            )}
          <SearchFacetFilters
            facets={this.props.facets ? this.props.facets : []}
            appliedFacets={this.state.appliedFacets}
            handleAddFacet={this.handleAddFacet}
            handleFacetList={this.handleFacetList}
            angularScope={this.state.angularScope}
            keyword={this.state.keyword}
            date={this.state.date}
            hasDate={this.props.has_date}
            handleDate={this.handleDate}
            jump={this.props.jump}
            counts={this.props.counts}
            isActiveDate={this.state.isActiveDate}
          />
        </div>
      );
    }
  }

  /**
   * @return {array}
   */
  function getReactSearchFacets() {
    return [REACT_SEARCH_MICROSITE_FACETS];
  }

  /**
   * Clears the local search facet storage.
   */
  function clearFacetsStorage(searchFacetType) {
    localStorage.removeItem(searchFacetType);
  }

  /**
   * Add analytics tracking when a keyword is entered.
   *
   * @param keyword
   *   The keyword string.
   */
  function addAnalyticsTracking(keyword) {
    var type = '';
    if (typeof Drupal.settings.rct_theme_search !== 'undefined') {
      type = 'collection ' + Drupal.settings.rct_theme_search.set_facets.type;
    } else if (typeof Drupal.settings.rct_microsite_search !== 'undefined') {
      type = 'microsite ' + Drupal.settings.rct_microsite_search.data.parentGroup;
    }
    let data = {
      event: 'search-autocomplete-keyword',
      searchType: type,
      searchTerm: keyword,
    };
    window.dataLayer.push(data);
  }

  /**
   * Updates the page visit counter for the local search facet storage on a 'non-search' page.
   */
  function updateNonSearchPageStorageVisitCounter() {
    const items = { ...localStorage };
    const reactSearchFacets = getReactSearchFacets();

    for (let i = 0; i < reactSearchFacets.length; ++i) {
      const searchFacetType = reactSearchFacets[i];
      if (!items.hasOwnProperty(searchFacetType)) {
        continue;
      }

      const searchFacetsStorage = items[searchFacetType];
      const searchFacetsStorageParsed = JSON.parse(searchFacetsStorage);
      const searchType = Object.keys(searchFacetsStorageParsed)[0];

      // We do not want to increase the page counter visit if the same page was visited consecutively (i.e via re-loading).
      if (searchFacetsStorageParsed[searchType].current_page === window.location.href) {
        return;
      }
      searchFacetsStorageParsed[searchType].current_page = window.location.href;

      // Increment the page counter visit each time the page visited is not the applicable search page.
      searchFacetsStorageParsed[searchType].non_applicable_search_page_visit_counter++;
      localStorage.setItem(searchFacetType, JSON.stringify(searchFacetsStorageParsed));

      // If the page counter visit is more than one, we clear the local storage facets
      // as this means the user has stepped outside the relevant search boundary.
      const maxSearchPageVisitBoundary = 1;
      if (searchFacetsStorageParsed[searchType].non_applicable_search_page_visit_counter > maxSearchPageVisitBoundary) {
        clearFacetsStorage(searchFacetType);
      }
    }
  }

  const el = document.querySelector('.react-search');
  if (!el) {
    // If there is a local storage for the search facets on a non-search page, increment the page visit counter.
    updateNonSearchPageStorageVisitCounter();
    return;
  }
  const facets = el.dataset.facets !== '' ? JSON.parse(el.dataset.facets) : [];
  const angular = el.dataset.angularSelector;
  const jumpLinks = el.dataset.jump;
  const searchBox = el.dataset.hasSearchBox !== '0';
  const counts = el.dataset.counts;
  var defaultSearchText = typeof el.dataset.defaultSearchText !== 'undefined' ? el.dataset.defaultSearchText : '';

  // Convert the search text back to forward slash where it was converted to using double hyphen.
  if (defaultSearchText.match(/\w.*\b(--)\w.*?(?:(?!--).)*/g)) {
    if (defaultSearchText != 'undefined') {
      defaultSearchText = defaultSearchText.replace(/--/g, '/');
    }
  }

  const hasDate = el.dataset.hasDate;
  const placeholder = typeof el.dataset.placeholder !== 'undefined' ? el.dataset.placeholder : 'Search by keyword';
  ReactDOM.render(
    <ReactSearch
      facets={facets}
      angular={angular}
      jump={jumpLinks}
      has_search_box={searchBox}
      default_search_text={defaultSearchText}
      counts={counts}
      has_date={hasDate}
      placeholder={placeholder}
    />,
    el
  );
});
