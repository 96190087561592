import React, { Component } from 'react';
import classNames from 'classnames';
import request from 'superagent';
import './style.scss';

class BeforeAndAfterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      percentage: 50,
      dragging: false,
      fullScreen: false,
      touchDevice: 'ontouchstart' in window || 'onmsgesturechange' in window,
    };
    this._slide = this._slide.bind(this);
    this._setDragging = this._setDragging.bind(this);
  }

  _slide() {
    this.setState({
      percentage: this._input.value,
    });
  }

  _setDragging(dragging) {
    this.setState({
      dragging: dragging,
    });
  }

  _fullScreen() {
    var element = this.refs.before_after_image;
    if (!this.state.fullScreen) {
      this.setState({
        fullScreen: true,
      });
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      this.setState({
        fullScreen: false,
      });
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  render() {
    const classes = classNames({
      'react-before-and-after-component': true,
      dragging: this.state.dragging,
      'in-full-view': this.state.fullScreen,
    });

    const styles = {
      width: this.state.percentage + '%',
      backgroundImage: 'url(' + this.props.data.beforeImage + ')',
      borderRight: '2px solid #fff',
    };

    return (
      <div id="before-after-image" ref="before_after_image" className={classes}>
        <div className="button">{this.props.data.label1}</div>
        <div className="button label-right">{this.props.data.label2}</div>
        <figure>
          <img src={this.props.data.afterImage} />
          <div style={styles} />
        </figure>
        <div className="compare-controls">
          <input
            type="range"
            min="0"
            max="100"
            value={this.state.percentage}
            ref={c => (this._input = c)}
            onChange={this._slide}
            onMouseDown={this._setDragging.bind(this, true)}
            onMouseUp={this._setDragging.bind(this, false)}
            onTouchStart={this._setDragging.bind(this, true)}
            onTouchEnd={this._setDragging.bind(this, false)}
          />
        </div>
        {!this.state.touchDevice && (
          <button className="expand-full-screen button circle hidden-xs" onClick={this._fullScreen.bind(this)}>
            <i className="icon-RCT-expand xs-top"></i>
          </button>
        )}
      </div>
    );
  }
}

export default BeforeAndAfterComponent;
