import './paragraph-visit-cards-tabbed.tpl.php';
import './style.scss';

jQuery($ => {
  const el = $('.paragraph-visit-cards-tabbed');

  if (!el) {
    return;
  }

  el.find('.tab_content').hide();
  el.find('.tab_content:first').show();

  let currentActiveTab = '';

  el.find('ul.tabs li').click(function(e) {
    const activeTab = el.find(this).attr('rel');
    if (currentActiveTab == activeTab) {
      return false;
    }

    if (activeTab === 'tab2' && jQuery('.locations-map').length > 0) {
      setTimeout(() => {
        mapInit();
      }, 100);
    }

    currentActiveTab = activeTab;
    el.find('.tab_content').hide();
    el.find(`#${activeTab}`).fadeIn();

    el.find('ul.tabs li').removeClass('active');
    el.find(this).addClass('active');

    el.find('.tab_drawer_heading').removeClass('d_active');
    el.find(`.tab_drawer_heading[rel^='${activeTab}']`).addClass('d_active');

    e.preventDefault();
  });

  el.find('.tab_drawer_heading').click(function() {
    const d_activeTab = el.find(this).attr('rel');
    el.find(`#${d_activeTab}`).fadeToggle();

    el.find(this).toggleClass('d_active');

    el.find('ul.tabs li').removeClass('active');
    el.find(`ul.tabs li[rel^='${d_activeTab}']`).addClass('active');

    const activeTab = el.find(this).attr('rel');
    if (currentActiveTab == activeTab) {
      return false;
    }
    if (activeTab === 'tab2' && jQuery('.locations-map').length > 0) {
      setTimeout(() => {
        mapInit();
      }, 100);
    }
  });

  el.find('ul.tabs li')
    .last()
    .addClass('tab_last');

  el.find('.tab_drawer_heading')
    .last()
    .addClass('tab_drawer_heading_last');
});
