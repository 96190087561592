import './style.scss';
import './paragraph-related-content.tpl.php';
require('slick-carousel');

jQuery(() => {
  if (!jQuery('.slick-carousel.carousel').length) {
    return;
  }

  jQuery('.slick-carousel.carousel').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: 'unslick',
      },
    ],
  });

  jQuery('.paragraph-related-content .video-promo').each((i, elem) => {
    const el = jQuery(elem);
    const modal = el.find('.video-promo__modal');
    const src = modal
      .find('.video-promo__modal-window')
      .children('iframe')
      .attr('src');

    modal.appendTo(document.body);
    el.find('.video-promo__play-button').click(e => {
      e.preventDefault();
      modal
        .find('.video-promo__modal-window')
        .children('iframe')
        .attr('src', src);
      modal.show();
    });
    modal.find('.video-promo__close-button').click(e => {
      e.preventDefault();
      modal
        .find('.video-promo__modal-window')
        .children('iframe')
        .attr('src', '');
      modal.hide();
      jQuery('body').removeClass('modal-open');
    });
  });
});
