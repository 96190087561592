import './paragraph-gallery-widget.tpl.php';
import './style.scss';
require('slick-carousel');

jQuery($ => {
  $(initialize);
  window.galleryWidget = initialize;
});

function initialize($) {
  if (!jQuery('.init-slick-carousel.carousel').length) {
    return;
  }

  // Loop through instances of paragraph widgets.
  jQuery('.paragraph-gallery-widget').each(function() {
    var context = $(this);

    // Init slick carousel within the parent context.
    jQuery('.init-slick-carousel.carousel', context).slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      asNavFor: jQuery('.init-slick-carousel.nav', context),
    });

    // Init slick carousel nav within the parent context.
    jQuery('.init-slick-carousel.nav', context).slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      asNavFor: jQuery('.init-slick-carousel.carousel', context),
      dots: true,
      centerMode: true,
      focusOnSelect: true,
      dotsClass: 'custom_paging',
      customPaging: function(slider, i) {
        return i + 1 + ' of ' + slider.slideCount;
      },
    });
  });
}
