import React from 'react';

const AutoComplete = props => {
  let className = 'search-auto-complete__autocomplete';
  if (!props.isVisible) {
    className += ' hidden';
  }
  if (props.error) {
    return <div>Error: {props.error.message}</div>;
  } else {
    return (
      <div className={className}>
        <span
          className={
            props.cursor === -1
              ? 'search-auto-complete__autocomplete__input-text active'
              : 'search-auto-complete__autocomplete__input-text'
          }
          onClick={event => props.handleKeyword(props.value)}
        >
          Search "<span className="search-auto-complete__autocomplete__keyword">{props.value}</span>" as a keyword...
        </span>
        <span className="search-auto-complete__autocomplete__title">Filter suggestions</span>
        {!props.isLoaded && props.value.length < props.minAutocomplete && (
          <span className="search-auto-complete__autocomplete__loader">Continue typing to see suggestions...</span>
        )}
        {!props.isLoaded && props.value.length >= props.minAutocomplete && (
          <div className="search-auto-complete__loading">
            Loading
            <span />
          </div>
        )}
        {props.isLoaded && Array.isArray(props.items) && (
          <ul>
            {props.items.map((item, i) => {
              let displayType = typeof item.display_type !== 'undefined' ? item.display_type : item.type;
              displayType = displayType.charAt(0).toUpperCase() + displayType.slice(1);
              return (
                <li
                  key={item.id}
                  className={props.cursor === i ? 'active' : null}
                  onClick={event => props.handleAddFacet(item)}
                >
                  <span className="search-auto-complete__autocomplete__name">
                    <Mark highlight={props.value}>{item.name}</Mark>
                  </span>
                  <span className="search-auto-complete__autocomplete__count">
                    {' '}
                    (<span className="search-auto-complete__autocomplete__subject">{displayType}, </span>
                    {item.count})
                  </span>
                </li>
              );
            })}
          </ul>
        )}
        {props.isLoaded && !props.items && (
          <div className="search-auto-complete__no-suggestions">No suggestions found</div>
        )}
      </div>
    );
  }
};

// Adapted from https://stackoverflow.com/questions/29652862/highlight-text-using-reactjs.
function Mark({ highlight, children }) {
  // Split text on higlight term, include term itself into parts, ignore case
  const parts = children.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? <mark key={index}>{part}</mark> : part
      )}
    </span>
  );
}

export default AutoComplete;
