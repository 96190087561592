import React from 'react';

import './style.scss';

const SearchFacetsApplied = props => {
  return (
    <div className="search-facets-applied">
      <div className="search-facets-applied__row">
        {!props.hasSearchBox && <p>Your applied filters</p>}
        <ul>
          {props.keyword ? (
            <li className="search-facet-filters__filter" onClick={e => props.removeKeyword(e)}>
              {props.keyword} (Keyword)
            </li>
          ) : null}
          {props.date ? (
            <li className="search-facet-filters__filter" onClick={e => props.removeDate(e)}>
              {props.date}
            </li>
          ) : null}
          {props.appliedFacets.map((item, index) => {
            let displayType = typeof item.display_type !== 'undefined' ? item.display_type : item.type;
            displayType = displayType.charAt(0).toUpperCase() + displayType.slice(1);
            return (
              <li
                className={props.active ? 'search-facet-filters__filter active' : 'search-facet-filters__filter'}
                key={index}
                onClick={e => props.removeFacet(index)}
              >
                {item.name && <span>{item.name} </span>}
                {item.type && <span>({displayType}) </span>}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SearchFacetsApplied;
