import './collection-object-image-area.tpl.php';
import './style.scss';
import './result-placeholder-350x350.png';
import './tail-spin.svg';
import 'script-loader!objectFitPolyfill/dist/objectFitPolyfill.min.js';
import 'script-loader!bootstrap-sass/assets/javascripts/bootstrap/modal.js';
import './iiif_viewer_icon.svg';

// Dirty hack to make this available to angular.
jQuery(initialize);
window.collectionObjectImageArea = initialize;

function initialize($) {
  window.objectFitPolyfill();

  const slickRefresh = () =>
    $('.slick-slider')
      .filter((k, e) => e.slick)
      .each((k, e) => e.slick.refresh());

  // Slick carousel functions.
  const mainSlick = $('.collection-object-image-area__init-slick-carousel.collection-object-image-area__carousel');
  mainSlick.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    asNavFor: '.collection-object-image-area__init-slick-carousel.collection-object-image-area__nav',
  });

  const navSlick = $('.collection-object-image-area__init-slick-carousel.collection-object-image-area__nav');
  navSlick.slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: '.collection-object-image-area__init-slick-carousel.collection-object-image-area__carousel',
    dots: false,
    centerMode: true,
    focusOnSelect: true,
  });

  const slickSliderCount = navSlick.slick('getSlick').slideCount;
  if (slickSliderCount <= 5) {
    navSlick.addClass('slick-no-slide');
  } else {
    navSlick.removeClass('slick-no-slide');
  }

  //Vimeo video id
  $('.collection-object-image-area__init-slick-carousel .object-thumbnail.object-video').each(function() {
    var el = $(this);
    var vimeoId = el.data('my-vimeo-id');
    var vimeoCallUrl = '//vimeo.com/api/oembed.json?url=http%3A//vimeo.com/' + vimeoId + '&maxwidth=300';
    $.getJSON(vimeoCallUrl, {
      format: 'json',
    }).done(function(data) {
      el.find('img').attr('src', data.thumbnail_url);
    });
  });

  // Zoom functions.
  $(' .init-zoom').click(function(e) {
    if ($('#full-screen-wrapper').hasClass('in-full-view')) {
      $('.collection-obj-full-screen').trigger('click');
    }

    $('.zoom-outer').show();
    $('#zoom_container').show();

    var activeSlide = $(' .slick-active .carousel-img');
    if (typeof activeSlide == 'undefined' || activeSlide.length == 0) {
      var activeSlide = $(' .slick-active .carousel-img');
    }
    var largeImageUrl = activeSlide.data('largeimg');
    $('#zoom_container')
      .css('background-image', 'url(./tail-spin.svg)')
      .smoothZoom({
        image_url: largeImageUrl,
        width: $(window).width(),
        height: $(window).height(),
        zoom_BUTTONS_SHOW: 'NO',
        pan_BUTTONS_SHOW: 'NO',
        pan_LIMIT_BOUNDARY: 'NO',
        zoom_MAX: 400,
      });
    $('html').addClass('disable-scroll');
    $('body').addClass('disable-scroll');
    e.preventDefault();
  });

  $(document).on('click', '.zoom-outer #close-zoom', function(e) {
    $('#zoom_container').fadeOut('fast', function() {
      $('.zoom-outer').hide();
      $('#zoom_container').hide();
      $('#zoom_container').smoothZoom('destroy');
      $('html').removeClass('disable-scroll');
      $('body').removeClass('disable-scroll');
    });
  });

  $(document).on('mousedown', '.zoom-outer .object-btns .zoom-in', function(e) {
    $('#zoom_container').smoothZoom('zoomIn');
  });

  $(document).on('mousedown', '.zoom-outer .object-btns .icon-zoom-out', function(e) {
    $('#zoom_container').smoothZoom('zoomOut');
  });

  // Fullscreen functions.
  $('.collection-obj-full-screen').click(function(e) {
    var full_screen_wrapper = $('#full-screen-wrapper');
    var element = full_screen_wrapper.get(0);
    if (full_screen_wrapper.hasClass('in-full-view')) {
      full_screen_wrapper.removeClass('in-full-view');
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    } else {
      full_screen_wrapper.addClass('in-full-view');
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    }
    e.preventDefault();
  });

  $(document).on('webkitfullscreenchange mozfullscreenchange fullscreenchange', function(e) {
    var state = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
    var event = state ? 'FullscreenOn' : 'FullscreenOff';
    if (event == 'FullscreenOff') {
      $('#full-screen-wrapper').removeClass('in-full-view');
    } else {
      $('#full-screen-wrapper').addClass('in-full-view');
    }
  });

  // Social functions.
  $('.share-page, .mobile-jump-to-page').click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    $('.object-share').toggle();
  });

  $('html').click(function() {
    if ($('.object-share:visible').length) {
      $('.object-share').hide();
    }
    if ($('.copy-widget.object-page').hasClass('visible')) {
      closeModal();
    }
  });

  $(document).off('click', '.collection-object-image-area .object-search-link .btn');

  $(document).on('click', '.collection-object-image-area .object-search-link .btn', function(e) {
    if ($(this).data('url')) {
      window.location = $(this).data('url');
    } else {
      window.location = '/collection/search';
    }
    e.preventDefault();
  });

  var openSharePopup = function(shareUrl, width, height) {
    var w = width;
    var h = height;
    var sharePopupParams = {
      top: screen.height / 2 - h / 2,
      left: screen.width / 2 - w / 2,
    };
    window.open(
      shareUrl,
      '',
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
        w +
        ', height=' +
        h +
        ', top=' +
        sharePopupParams.top +
        ', left=' +
        sharePopupParams.left
    );
  };

  if (window.location.href.indexOf('?close_window') != -1) {
    window.close();
  }

  $(document).on('click', '.collection-object-image-area .trigger-addthis-fb', function(e) {
    if (typeof ga !== 'undefined') {
      ga('send', 'social', 'Facebook', 'share', window.location.toString());
    }
    $('.object-share.popover').hide();
    if ($('.collection-object-image-area__carousel').length > 0) {
      var shareLink = window.location.href;
      var pinMedia = $('#object-media-carousel .slick-active img').attr('src');
      var fbShareObj = {
        app_id: '453894214777350',
        link: encodeURIComponent(shareLink),
        picture: pinMedia,
        name: $('meta[property="og:title"]').attr('content'),
        description: $('meta[name="description"]').attr('content'),
        redirect_uri: encodeURIComponent(shareLink) + '?close_window',
        display: 'popup',
        showError: false,
      };
      var finalFbShareLink =
        'https://www.facebook.com/dialog/feed?&app_id=' +
        fbShareObj.app_id +
        '&link=' +
        fbShareObj.link +
        '&picture=' +
        fbShareObj.picture +
        '&name=' +
        fbShareObj.name +
        '&description=' +
        fbShareObj.description +
        '&redirect_uri=' +
        fbShareObj.redirect_uri +
        '&display=' +
        fbShareObj.display +
        '&show_error=' +
        fbShareObj.showError;
      openSharePopup(finalFbShareLink, 580, 300);
    }
    e.preventDefault();
  });

  $(document).on('click', '.collection-object-image-area .trigger-addthis-tweet', function(e) {
    if (typeof ga !== 'undefined') {
      ga('send', 'social', 'Twitter', 'tweet', window.location.toString());
    }
    $('.object-share.popover').hide();
    $('.addthis_button_twitter').trigger('click');
    e.preventDefault();
  });

  $(document).on('click', '.collection-object-image-area .trigger-addthis-email', function(e) {
    if (typeof ga !== 'undefined') {
      ga('send', 'social', 'Email', 'share', window.location.toString());
    }
    $('.object-share.popover').hide();
    $('.addthis_button_email').trigger('click');
    e.preventDefault();
  });

  $(document).on('click', '.trigger-addthis-pin-it', function(e) {
    if (typeof ga !== 'undefined') {
      ga('send', 'social', 'Pintrest', 'share', window.location.toString());
    }
    var el = $('.pin-it');
    $('.object-share.popover').hide();
    //$('[data-toggle="popover"]').popover('hide');
    if ($('.collection-object-image-area__carousel').length > 0) {
      var shareLink = window.location.href;
      var pinMedia = $('.collection-object-image-area__carousel .slick-active .carousel-img img').prop('src');
      window.open('https://www.pinterest.com/pin/create/button/?url=' + shareLink + '&media=' + pinMedia);
    }
    e.preventDefault();
  });

  $(document).on('click', '.trigger-share-object-link', function(e) {
    if (typeof ga !== 'undefined') {
      ga('send', 'social', 'Share link', 'share', window.location.toString());
    }
    $('#message-modal-object').modal('show');

    var shareLink = window.location.href;
    $('#copy-dynamic-input-object').val(shareLink);
    $('.copy-widget.object-page').addClass('visible');
    $(document).on('click focus', '#copy-dynamic-input-object', function(e) {
      e.stopPropagation();
      //some code
    });
    setTimeout(function() {
      $('#copy-dynamic-input-object').select();
    }, 100);
    e.preventDefault();
  });

  $(document).on('click touchstart', '.close-share-link-object', closeModal);

  function closeModal() {
    $('.copy-widget.object-page').removeClass('visible');
    setTimeout(function() {
      $('#message-modal-object').modal('hide');
    }, 100);
  }

  /**
   * Adds IIIF viewer element to the modal.
   *
   * @param $iiifViewerElement
   */
  function addIIIFToModal($iiifViewerElement) {
    let existingIframe = document.getElementsByClassName('modal__content')[0].getElementsByTagName('iframe')
      ? document.getElementsByClassName('modal__content')[0].getElementsByTagName('iframe')[0]
      : null;
    if (existingIframe) {
      document
        .getElementsByClassName('modal__content')[0]
        .replaceChild($iiifViewerElement.cloneNode(true), existingIframe);
    } else {
      document.getElementsByClassName('modal__content')[0].appendChild($iiifViewerElement.cloneNode(true));
    }
  }

  /**
   * Opens the custom IIIF modal for non-fullscreen support.
   *
   * @param $iframe
   */
  function openIIIFModal($iframe) {
    let modal = document.getElementById('iiif-modal');
    modal.style.display = 'block';

    // We add a clone of the iframe when it opens instead of beforehand so that it clones and loads properly.
    addIIIFToModal($iframe);
  }

  /**
   * Closes the custom IIIF modal for non-fullscreen support.
   */
  function closeIIIFModal() {
    let modal = document.getElementById('iiif-modal');
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = 'none';
      }
    };

    let iiifModalClose = document.getElementsByClassName('modal__close')[0];
    iiifModalClose.onclick = function() {
      modal.style.display = 'none';
    };
  }

  // IIIF iframe universal viewer trigger.
  const $hasIIIFMedia = document.querySelector('.has-iiif-media');
  if ($hasIIIFMedia) {
    const carousel = document.querySelectorAll(
      '.collection-object-image-area__carousel .image-wrapper:not(.slick-cloned)'
    );

    let $iiif_placeholder = document.querySelector('.iiif-placeholder');

    for (let i = 0; i < carousel.length; i++) {
      let iiif_reference = carousel[i].getAttribute('data-iiif-reference');
      if (iiif_reference !== null) {
        let iiif_id = carousel[i].getAttribute('id');
        let div = document.createElement('div');
        div.setAttribute('id', iiif_id + '-frame');
        div.setAttribute('class', 'uv');
        div.setAttribute('data-locale', 'en-GB:English (GB)');
        div.setAttribute('data-fullscreen', '0');
        div.setAttribute('data-config', '');
        div.setAttribute('data-uri', iiif_reference);
        div.setAttribute('data-collectionindex', '0');
        div.setAttribute('data-manifestindex', '0');
        div.setAttribute('data-sequenceindex', '0');
        div.setAttribute('data-canvasindex', '0');
        div.setAttribute('data-zoom', '-1.0064,0,3.0128,1.3791');
        div.setAttribute('data-rotation', '-1.0064,0,3.0128,1.3791');
        div.setAttribute('style', 'width:850px; height:677px; margin: 0 auto');
        $iiif_placeholder.appendChild(div);
      }

      let button = carousel[i].querySelector('.iiif-launch-button');

      // Set an interval to check when the iframe is loaded.
      // We use the "Loading..." as a button label when it is not loaded yet.
      let checkFullScreenExistCount = 0;
      let checkFullScreenExist = setInterval(function() {
        let id = carousel[i].getAttribute('id');
        if (id === null) {
          clearInterval(checkFullScreenExist);
          return;
        }

        if (checkFullScreenExistCount > 100) {
          clearInterval(checkFullScreenExist);
        }
        checkFullScreenExistCount++;

        if (document.getElementById(id + '-frame') != null) {
          let iframe = document.getElementById(id + '-frame').getElementsByTagName('iframe')[0];

          if (typeof iframe != 'undefined') {
            let fullScreenButton = iframe.contentWindow.document.getElementsByClassName('uv-icon-fullscreen');
            if (fullScreenButton.length > 0) {
              button.innerText = 'Launch viewer';
              iframe.setAttribute('allowfullscreen', 'true');
              clearInterval(checkFullScreenExist);
            } else {
              button.innerText = 'Loading...';
            }
          }
        }
      }, 200);

      if (button !== null) {
        closeIIIFModal();

        button.addEventListener(
          'click',
          function(e) {
            let id = this.getAttribute('id');
            let iframe = document.getElementById(id + '-frame').getElementsByTagName('iframe')[0];

            if (typeof iframe != 'undefined') {
              let fullScreenButton = iframe.contentWindow.document.getElementsByClassName('uv-icon-fullscreen');
              if (typeof fullScreenButton[0] !== 'undefined') {
                var hasFullscreenSupport = iframe.contentWindow.document
                  .querySelector('#uv  > div > div.uv')
                  .classList.contains('fullscreen-supported');
                // If it is not full screen supported, use a modal.
                if (!hasFullscreenSupport) {
                  openIIIFModal(iframe);
                } else {
                  fullScreenButton[0].click();
                }
              }
            }
          }.bind(carousel[i])
        );
      }
    }

    let $embedUVscript = document.createElement('script');
    $embedUVscript.setAttribute('type', 'text/javascript');
    $embedUVscript.setAttribute('id', 'embedUV');
    $embedUVscript.setAttribute('src', '/sites/all/libraries/universalviewer/dist/lib/embed.js');

    let $embedUVscript2 = document.createElement('script');
    $embedUVscript2.setAttribute('type', 'text/javascript');

    let $content = '';
    if (document.body.classList.contains('page-collection-search')) {
      $content = document.querySelector('.object-container .block-system .content');
    } else {
      $content = document.querySelector('.page');
    }

    if ($content !== null) {
      $content.appendChild($embedUVscript);
      $content.appendChild($embedUVscript2);
    }
  }
}
