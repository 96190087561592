import React, { Component } from 'react';
import 'react-dates/initialize';
import 'airbnb-browser-shims';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import './style.scss';

import { START_DATE, VERTICAL_ORIENTATION, HORIZONTAL_ORIENTATION } from 'react-dates/constants';
const smallDevice = window.matchMedia('(max-width: 1020px)').matches;
const orientation = smallDevice ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION;

class SearchFacetDates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      focusedInput: START_DATE,
      withPortal: false,
      orientation,
      startYear: '',
      endYear: '',
    };
    this.handleWindowSize = this.handleWindowSize.bind(this);
    this.handleChangeYear = this.handleChangeYear.bind(this);
    this.handleApplyYear = this.handleApplyYear.bind(this);
  }

  handleWindowSize() {
    const smallDevice = window.matchMedia('(max-width: 1020px)').matches;
    const orientation = smallDevice ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION;
    this.setState({
      withPortal: smallDevice,
      orientation,
    });
  }

  componentWillMount() {
    this.handleWindowSize();
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSize);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSize);
  }

  handleHideDate() {
    this.setState({
      isVisible: false,
      focusedInput: null,
    });
  }

  handleShowDate() {
    this.setState({
      isVisible: !this.state.isVisible,
      focusedInput: START_DATE,
      startDate: null,
      endDate: null,
    });
  }

  handleShowToday() {
    this.props.handleDate({
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day'),
      text: 'today',
    });
  }

  handleShowTomorrow() {
    this.props.handleDate({
      startDate: moment()
        .startOf('day')
        .add(1, 'day'),
      endDate: moment()
        .endOf('day')
        .add(1, 'day'),
      text: 'tomorrow',
    });
  }

  handleShowWeek() {
    this.props.handleDate({
      startDate: moment(),
      endDate: moment().endOf('isoWeek'),
      text: 'this week',
    });
  }

  handleShowWeekend() {
    this.props.handleDate({
      startDate: moment()
        .weekday(5)
        .startOf('day'),
      endDate: moment().endOf('isoWeek'),
      text: 'this weekend',
    });
  }

  handleShowNextWeek() {
    this.props.handleDate({
      startDate: moment()
        .add(1, 'weeks')
        .startOf('isoWeek'),
      endDate: moment()
        .add(1, 'weeks')
        .endOf('isoWeek'),
      text: 'next week',
    });
  }

  handleShowMonth() {
    this.props.handleDate({
      startDate: moment(),
      endDate: moment().endOf('month'),
      text: 'this month',
    });
  }

  handleCustomRange(dates) {
    dates.type = 'custom';
    (dates.text = dates.startDate.format('DD/MM/YYYY') + ' - ' + dates.endDate.format('DD/MM/YYYY') + ' (When)'),
      this.props.handleDate(dates);
    this.props.toggleFacetList();
    this.setState({
      isVisible: false,
    });
  }

  handleChangeYear(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleApplyYear() {
    const dates = {
      startDate: this.state.startYear ? moment({ year: this.state.startYear }).startOf('year') : '',
      endDate: this.state.endYear ? moment({ year: this.state.endYear }).endOf('year') : '',
      type: 'custom_year',
      text: this.state.startYear + ' - ' + this.state.endYear + ' (Date)',
    };
    this.props.handleDate(dates);
    this.props.toggleFacetList();
    this.setState({
      isVisible: false,
    });
  }

  render() {
    return (
      <div className="search-facet-filter">
        {this.props.title && <p>{this.props.title.charAt(0).toUpperCase() + this.props.title.slice(1)}</p>}
        <div className="search-facet-filters__row">
          <div className="react-search__date-wrapper">
            <ul className="search-facet-filters__list">
              {!this.props.showByYear && (
                <li className={'search-facet-filters__filter'} onClick={() => this.handleShowDate()}>
                  A specific date range
                  {this.state.isVisible === true && (
                    <div className="search-facet-dates__picker">
                      <div>
                        <div className="search-facet-dates__arrow" />
                        <DayPickerRangeController
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                          focusedInput={this.state.focusedInput}
                          onFocusChange={focusedInput => this.setState({ focusedInput })}
                          onClose={({ startDate, endDate }) => this.handleCustomRange({ startDate, endDate })}
                          numberOfMonths={2}
                          isOutsideRange={day => day.startOf('day').isBefore(moment().startOf('day'))}
                          minimumNights={0}
                          orientation={this.state.orientation}
                          withPortal={this.state.withPortal}
                        />

                        {this.state.withPortal && (
                          <button
                            className="search-facet-dates__close"
                            onClick={e => {
                              this.handleHideDate();
                              this.props.toggleFacetList(e);
                            }}
                          >
                            {' '}
                            <i className="icon-cross2" />{' '}
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </li>
              )}
              {this.props.items.month > 0 && (
                <li
                  className={
                    this.props.isActiveDate === 'this month'
                      ? 'search-facet-filters__filter search-facet-filters__filter--active'
                      : 'search-facet-filters__filter'
                  }
                  onClick={e => {
                    this.handleShowMonth();
                    this.props.toggleFacetList(e);
                  }}
                >
                  This month
                </li>
              )}
              {this.props.items['this-weekend'] > 0 && (
                <li
                  className={
                    this.props.isActiveDate === 'this weekend'
                      ? 'search-facet-filters__filter search-facet-filters__filter--active'
                      : 'search-facet-filters__filter'
                  }
                  onClick={e => {
                    this.handleShowWeekend();
                    this.props.toggleFacetList(e);
                  }}
                >
                  This weekend
                </li>
              )}
              {this.props.items.week > 0 && (
                <li
                  className={
                    this.props.isActiveDate === 'this week'
                      ? 'search-facet-filters__filter search-facet-filters__filter--active'
                      : 'search-facet-filters__filter'
                  }
                  onClick={e => {
                    this.handleShowWeek();
                    this.props.toggleFacetList(e);
                  }}
                >
                  This week
                </li>
              )}
              {this.props.items['next-week'] > 0 && (
                <li
                  className={
                    this.props.isActiveDate === 'next week'
                      ? 'search-facet-filters__filter search-facet-filters__filter--active'
                      : 'search-facet-filters__filter'
                  }
                  onClick={e => {
                    this.handleShowNextWeek();
                    this.props.toggleFacetList(e);
                  }}
                >
                  Next week
                </li>
              )}
              {this.props.items.tomorrow > 0 && (
                <li
                  className={
                    this.props.isActiveDate === 'tomorrow'
                      ? 'search-facet-filters__filter search-facet-filters__filter--active'
                      : 'search-facet-filters__filter'
                  }
                  onClick={e => {
                    this.handleShowTomorrow();
                    this.props.toggleFacetList(e);
                  }}
                >
                  Tomorrow
                </li>
              )}
              {this.props.items.today > 0 && (
                <li
                  className={
                    this.props.isActiveDate === 'today'
                      ? 'search-facet-filters__filter search-facet-filters__filter--active'
                      : 'search-facet-filters__filter'
                  }
                  onClick={e => {
                    this.handleShowToday();
                    this.props.toggleFacetList(e);
                  }}
                >
                  Today
                </li>
              )}
              {this.props.showByYear && (
                <li className="search-facet-dates__year">
                  <div className="search-facet-dates__year__input-row">
                    <div className="search-facet-dates__year__input-column">
                      <span>From</span>
                      <input type="number" placeholder="1819" name="startYear" onChange={this.handleChangeYear} />
                    </div>
                    <div className="search-facet-dates__year__input-column">
                      <span>To</span>
                      <input type="number" placeholder="1861" name="endYear" onChange={this.handleChangeYear} />
                    </div>
                  </div>
                  <button className="search-facet-dates__year__button" onClick={this.handleApplyYear}>
                    Apply
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchFacetDates;
