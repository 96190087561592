import './style.scss';
import './paragraph-before-after-image.tpl.php';
import React, { Component } from 'react';
import { render } from 'react-dom';
import BeforeAndAfterComponent from './reactBeforeAndAfterComponent';

jQuery($ => {
  $(initialize);
  window.beforeAfter = initialize;
});

function initialize($) {
  if ($('.react-before-and-after-image-component').length > 0) {
    $('.react-before-and-after-image-component').each(function () {
      const componentData =  $(this).data('component-data');

      render(
        <BeforeAndAfterComponent data={componentData} />,
        this
      );
    });
  }
}
