import './style.scss';
import './paragraph-timeline.tpl.php';

jQuery($ => {
  var timeline = {
    timeline: {
      headline: 'A New Timeline',
      type: 'default',
      text: '<p>This is a paragraph.</p>',
      date: [
        {
          startDate: '1/1/2014',
          endDate: '1/5/2014',
          headline: 'deer',
          text: 'grass',
          tag: '430',
        },
        {
          startDate: '2/1/2014',
          endDate: '7/5/2014',
          headline: 'cat',
          text: 'fish',
          tag: '20',
        },
        { startDate: '3/1/2014', endDate: '9/1/2014', headline: 'eagle', text: 'mice', tag: '100' },
      ],
      era: [
        {
          startDate: '2000',
          endDate: '2020',
          headline: 'era headline',
          text: 'era text',
          tag: 'era tag',
        },
      ],
    },
  };

  if (typeof createStoryJs !== 'undefined') {
    createStoryJS({
      type: 'timeline',
      width: '100%',
      height: '600',
      source: timeline,
      embed_id: 'timeline',
    });
  }

  // Timeline full screen
  $('.expand-full-screen').click(function(e) {
    makeFullScreen('.timeline-wrapper');
    e.preventDefault();
  });

  if (document.addEventListener) {
    document.addEventListener('webkitfullscreenchange', exitFullScreenHandler, false);
    document.addEventListener('mozfullscreenchange', exitFullScreenHandler, false);
    document.addEventListener('fullscreenchange', exitFullScreenHandler, false);
    document.addEventListener('MSFullscreenChange', exitFullScreenHandler, false);
  }

  function makeFullScreen(target) {
    var target = typeof target !== 'undefined' ? target : '.timeline-wrapper';
    var full_screen_wrapper = $(target); //$(this).parents('.object-btns').siblings('.object-media-carousel').children('#full-screen-wrapper');
    var element = full_screen_wrapper.get(0);
    if (full_screen_wrapper.hasClass('in-full-view')) {
      full_screen_wrapper.removeClass('in-full-view');
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    } else {
      full_screen_wrapper.addClass('in-full-view');
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    }
  }

  function exitFullScreenHandler() {
    var full_screen_wrapper = $('.timeline-wrapper');
    if (
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      (typeof document.msFullscreenElement !== 'undefined' && document.msFullscreenElement !== null)
    ) {
      full_screen_wrapper.addClass('in-full-view');
    } else {
      $('.in-full-view').removeClass('in-full-view');
    }
  }
});
