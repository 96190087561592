import React, { Component } from 'react';
import SearchFacetFilter from './SearchFacetFilter';

import './style.scss';
import SearchFacetDates from '../searchFacetDates';

class SearchFacetFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      toggled: this.props.jump === 'jump',
      loading: true,
    };

    this.toggleFacetList = this.toggleFacetList.bind(this);
  }

  getFacetData(facet, order) {
    this.setState({ loading: true });
    if (facet.type === 'jump') {
      this.setState({
        items: [
          {
            type: 'jump',
            title: '',
            data: Drupal.settings.react_jump_links,
          },
        ],
        loading: false,
      });
    } else {
      const requestData = this.props.angularScope
        ? this.props.angularScope.reactFacetList(this.props.facets)[facet.type]
        : this._getMockData();
      requestData.then(result => {
        let items = this.state.items;
        let newFacet = {
          type: facet.type,
          title: facet.title,
          data: result.results,
          order: order,
        };
        let index = items.findIndex(item => item.type === facet.type);
        if (index === -1) {
          items.push(newFacet);
        } else {
          items[index] = newFacet;
        }
        items.sort((a, b) => a.order - b.order);
        this.props.handleFacetList(items);

        this.setState({
          items: items,
          loading: false,
        });
      });
    }
  }

  _getMockData() {
    return fetch(
      'https://api.rct.uk/collection/search-api/theme/subjects?searchTerm=&hasImages=false&orderBy=date&orderDirection=desc&page=1&themeType=trail&whatsOnDate=&whatsOnEndDateIsPast=false&whatsOnAccess=0&excludeNode=&itemsPerPage=8&eventType=whatsOn'
    ).then(res => res.json());
  }

  componentDidMount() {
    let i = 0;
    this.props.facets.forEach(facet => {
      this.getFacetData(facet, i);
      i++;
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.appliedFacets !== prevProps.appliedFacets ||
      this.props.keyword !== prevProps.keyword ||
      this.props.date !== prevProps.date
    ) {
      this.componentDidMount();
    }
  }

  toggleFacetList() {
    this.setState({ toggled: !this.state.toggled });
  }

  getSearchFacetRender() {
    if (Object.keys(this.state.items).length === 0) {
      return;
    }

    // whatson date set lebel to be when | when year set label to null (empty string)
    let ret = this.state.items.map(item => {
      if (item.type === 'whatson_date' || item.type === 'date_year') {
        const showByYear = item.type === 'date_year' ? true : false;
        return (
          <SearchFacetDates
            key="date"
            date={this.props.date}
            handleDate={this.props.handleDate}
            appliedFacets={this.props.appliedFacets}
            toggleFacetList={this.toggleFacetList}
            activeDate={this.props.isActiveDate}
            handleIsActiveDate={this.props.handleIsActiveDate}
            isActiveDate={this.props.isActiveDate}
            items={item.data}
            title={item.title}
            showByYear={showByYear}
          />
        );
      }
      return (
        <SearchFacetFilter
          key={item.type}
          items={item.data}
          title={item.title}
          handleAddFacet={this.props.handleAddFacet}
          appliedFacets={this.props.appliedFacets}
          loading={this.state.loading}
          toggleFacetList={this.toggleFacetList}
          date={this.props.date}
          handleDate={this.props.handleDate}
          handleIsActiveDate={this.props.handleIsActiveDate}
          isActiveDate={this.props.isActiveDate}
          counts={this.props.counts}
        />
      );
    });
    ret.push();

    return ret;
  }

  render() {
    const searchFacets = this.getSearchFacetRender();
    let string = '';
    if (this.props.jump === 'jump') {
      string = 'Jump to content type';
    } else {
      string = 'Filter results';
      if (this.props.appliedFacets.length >= 1) {
        string = 'Apply more filters';
      }
    }

    if (this.props.facets.length >= 1) {
      return (
        <div className="search-facet-filters ">
          <span className="search-facet-filters__filter-icon" />
          <a className="search-facet-filters__link" onClick={this.toggleFacetList}>
            {string}
          </a>
          <span
            className={
              this.state.toggled
                ? 'search-facet-filters__filter-arrow search-facet-filters__filter-arrow--toggled'
                : 'search-facet-filters__filter-arrow'
            }
            onClick={this.toggleFacetList}
          />
          {this.state.toggled === true && <div>{searchFacets}</div>}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default SearchFacetFilters;
