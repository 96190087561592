import './paragraph-essential-information-modals.tpl.php';
import './style.scss';
import yearPassLogo from './year-pass-logo.png';

jQuery($ => {
  const component = '.paragraph-essential-information-modals';
  const items = $(`${component}__item`);

  if (items.length < 0) {
    return;
  }

  const myhash = window.location.hash.replace(/^##?\/?/, '');
  try {
    if (myhash && myhash.length && $(`${component}__${myhash}`).length) {
      const target = $(`${component}__${myhash}`);

      if (target !== null) {
        setTimeout(() => {
          const openButton = target.find(`${component}__button`);
          $(openButton).click();
        }, 100);
      }
    }
  } catch (err) {}

  items.each((i, el) => {
    const item = $(el);

    const openButton = item.find(`${component}__button`);
    const closeButton = item.find(`${component}__button-close`);
    const modalWindow = item.find(`${component}__modal-window`);

    openButton.click(() => {
      modalWindow.show();
      $('body').addClass('modal-open');
    });

    closeButton.click(() => {
      modalWindow.hide();
      $('body').removeClass('modal-open');
    });

    // Search DOM for links which will trigger modals.
    const modalId = item.data('modal-id');
    const linkSelector = "a[href*='##" + modalId + "']";
    const modalLinks = $(linkSelector);

    // Loop through modal links.
    modalLinks.each((key, link) => {
      const anchor = $(link);

      // Modal link click event.
      anchor.click(() => {
        // Get modal link href without double hash anchor.
        let linkTarget = anchor.attr('href').split('##')[0];

        // Only open modal if link target matches current window location.
        if (window.location.pathname === linkTarget) {
          modalWindow.show();
          $('body').addClass('modal-open');
        }
      });
    });
  });
});
