import './style.scss';
import './paragraph-faqs.tpl.php';

jQuery($ => {
  $(document).off('click', '.paragraph-faqs .accordion > li h6 a');
  $(document).on('click', '.paragraph-faqs .accordion > li h6 a', function(e) {
    $('.paragraph-faqs .accordion > li').removeClass('selected');
    $(this)
      .closest('li')
      .addClass('selected');

    const dropDown = $(this)
      .closest('li')
      .find('.accordion-inside');
    $(this)
      .closest('.accordion-inside')
      .not(dropDown)
      .slideUp();

    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
    } else {
      $(this).addClass('active');
    }

    dropDown.stop(false, true).slideToggle();

    e.preventDefault();
    return false;
  });

  // Google JSON-LD schema 
  const faqElement = document.querySelector('.paragraph-faqs');
  if (!faqElement) {
    return;
  }
  const faqs = [...faqElement.getElementsByClassName('faq')];

  const faqsData = faqs.map(faq => {
    const faqQuestion = faq.querySelector('.faq-title').textContent.trim();
    const faqAnwser = faq.querySelector('.accordion-inside').innerHTML;
    return {
      "@type": "Question",
      "name": faqQuestion ,
      "acceptedAnswer": {
        "@type": "Answer",
        "text":  faqAnwser
      }}
  });

  const faqsSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqsData
  }
  const schemaScript = document.createElement('script');
  schemaScript.setAttribute('type', 'application/ld+json');
  schemaScript.textContent = JSON.stringify(faqsSchema);
  document.head.appendChild(schemaScript);
});
