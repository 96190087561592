import './paragraph-hubspot-form.tpl.php';
import './style.scss';

jQuery($ => {
  const hubspotFormComponents = Array.prototype.slice.call(
    document.querySelectorAll(
      ".paragraph-hubspot-form .paragraph-hubspot-form-wrapper[data-hubspot-form-target='true']"
    )
  );
  if (!hubspotFormComponents.length) {
    return;
  }

  const s = document.createElement('script');
  s.setAttribute('src', '//js.hsforms.net/forms/v2.js');

  /**
   * Set CSS for hubspot forms in this variable.
   *
   */
  const formCss = `
        .hs-form * {
            line-height: 19px;
            font-size: 14px;
            color: #333;
        }
        @media only screen and (min-width: 768px) {
            .hs-form * {
                line-height: 20px;
                font-size: 15px;
            }
        }
        h4 {
            font-size: 28px !important;
            margin-bottom: 12px;
            line-height: 32px !important;
            font-family: Georgia,serif;
            font-weight: normal;
        }

        input, .hs-input {
            border: 1px solid #d0d0d0;
            color: #333;
            border-radius: 3px;
            font-size: 14px;
            line-height: 19px;
            padding: 12px 15px;
            height: 48px;
            background: white;
        }
        @media only screen and (min-width: 768px) {
            input, .hs-input {
                line-height: 20px;
                font-size: 15px;
            }
        }
        .hs-input[type=file] {
            font-size: 15px;
            line-height: 20px;
            padding: 12px 0;
        }
        .hs-input[type=checkbox] {
            -webkit-appearance: none;
            border: 1px solid #8a2a2b;
            padding: 9px;
            border-radius: 3px;
            display: inline-block;
            position: relative;
            margin: 0 10px 0 0;
        }
        .hs-input[type=checkbox]:checked {
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjEuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNTIuNiAxMTMuNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTUyLjYgMTEzLjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojOEEyQTJCO30KPC9zdHlsZT4KPGc+Cgk8Zz4KCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNTUuNCwxMTMuNGwtNTItNTJjLTQuNS00LjUtNC41LTExLjcsMC0xNi4yYzQuNS00LjUsMTEuNy00LjUsMTYuMiwwTDU1LjQsODFMMTMzLDMuNAoJCQljNC41LTQuNSwxMS43LTQuNSwxNi4yLDBzNC41LDExLjcsMCwxNi4yTDU1LjQsMTEzLjR6Ii8+Cgk8L2c+CjwvZz4KPC9zdmc+Cg==) no-repeat 0px 3px;
        }   
        .hs-input[type=radio] {
           -webkit-appearance: none;
            background-color: #fff;
            border: 1px solid #8a2a2b;
            padding: 9px;
            border-radius: 50px;
            display: inline-block;
            position: relative;
            margin: 0 10px 0 0;
        }
        
        .hs-input[type=radio]:checked:after {
            content: ' ';
            width: 12px;
            height: 12px;
            border-radius: 50px;
            position: absolute;
            top: 3px;
            background: #8a2a2b;
            text-shadow: 0px;
            left: 3px;
            font-size: 32px;
        }
        
        .hs-form-checkbox-display span,
        .hs-form-radio-display span {
            position: relative;
            top: -4px;
        }
        label {
            font-weight: bold;
        }
        .hs-error-msgs label {
            color: #8A2A2B;
        }
        .hs-form-required {
            color: #8A2A2B;
        }
        .hs-button {
            font-size: 17px !important;
            line-height: 22px;
            text-transform: uppercase;
            font-weight: normal;
        }
        a {
            border-bottom: 1px dotted #8a2a2b;
            color: #8A2A2B !important;
            text-decoration: none;
            font-weight: normal;
        }
        .hs-field-desc {
            color: #333!important;
            line-height: 18px !important;
            font-size: 13px !important;
        }
        @media only screen and (min-width: 768px) {
            .hs-field-desc {
                line-height: 19px !important;
                font-size: 14px !important;
            }
        }
        .fn-date-picker .pika-button {
            text-align: center;
            background: #f4f4f4;
            color: #333;
            border: 1px solid #fff;
        }
        .fn-date-picker .is-selected .pika-button {
            background: #8a2a2b;
            box-shadow: inset 0 1px 3px #8a2a2b;
                border-radius: 0;
        }
        .fn-date-picker .pika-button:hover {
            background: #d8d8d8 !important;
            color: #333 !important;
        }
        .fn-date-picker .is-today .pika-button {
            background: #f4ebeb;
            color: #333;
        }
        .fn-date-picker .pika-table abbr {
            text-decoration: none;
        }
        input:required,
        select:required,
        textarea:required {
            background-color: #f4ebeb;
        }
    `;

  s.onload = () => {
    hubspotFormComponents.map(element => {
      hbspt.forms.create({
        target: `#${element.id}`,
        portalId: element.dataset.hubspotPortalId,
        formId: element.dataset.hubspotFormId,
        onFormReady: function(form) {
          // Navigate up the DOM tree from from the jquery form component to find the head element
          const html = form.closest('html');
          const head = html.find('head');
          head.append(`<style>${formCss}</style>`);
        },
      });
    });
  };
  document.body.appendChild(s);
});
